import MomentumLogoIcon from './MomentumLogo'

export default MomentumLogoIcon

// export default function LaserfocusLogoIcon(props: SvgIconProps) {
//     return (
//         <SvgIcon viewBox="0 0 80 80" {...props}>
//             <circle cx="40" cy="40" r="40" fill="#0E17B5" />
//             <ellipse cx="40.0002" cy="40" rx="32.3478" ry="32.3478" fill="url(#paint0_linear)" />
//             <ellipse cx="40" cy="40" rx="22.9565" ry="22.9565" fill="#EDEFF9" />
//             <defs>
//                 <linearGradient
//                     id="paint0_linear"
//                     x1="40.0002"
//                     y1="7.65218"
//                     x2="40.0002"
//                     y2="72.3478"
//                     gradientUnits="userSpaceOnUse"
//                 >
//                     <stop stopColor="#1146EB" />
//                     <stop offset="1" stopColor="#0E17B5" />
//                 </linearGradient>
//             </defs>
//         </SvgIcon>
//     )
// }
