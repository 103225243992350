import { SvgIcon, SvgIconProps } from '../svg-icon'

export default function MomentumLogoIcon(props: SvgIconProps) {
    return (
        <SvgIcon width="49" height="40" viewBox="0 0 49 40" fill="none" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.499 28.2273L38.0031 38.361C39.0425 39.1249 40.2733 39.5818 41.5569 39.6804C42.8406 39.779 44.1261 39.5153 45.2688 38.919C46.4115 38.3227 47.366 37.4175 48.0248 36.3053C48.6836 35.1931 49.0205 33.918 48.9977 32.6236V9.81006L24.499 28.2273Z"
                fill="#0ECA99"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.4996 11.779L11.0236 1.64524C9.98526 0.876661 8.75407 0.415028 7.46881 0.312378C6.18356 0.209728 4.89534 0.470142 3.74938 1.06426C2.60341 1.65839 1.64527 2.56259 0.982958 3.67496C0.320646 4.78732 -0.0194999 6.06361 0.000863541 7.35995V30.1962L24.4996 11.779Z"
                fill="#20AFE4"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M48.9986 7.09382C49.0246 5.79566 48.6886 4.51612 48.0287 3.40006C47.3689 2.284 46.4114 1.37602 45.2649 0.778918C44.1183 0.181817 42.8284 -0.0805501 41.5411 0.0215244C40.2539 0.123599 39.0208 0.586037 37.9815 1.35648L0.00118251 29.9074V32.9119C-0.0226655 34.2092 0.314619 35.4874 0.974958 36.6021C1.6353 37.7168 2.59239 38.6238 3.73818 39.2204C4.88398 39.8171 6.17284 40.0798 7.45925 39.9789C8.74567 39.878 9.9784 39.4174 11.0183 38.6492L48.9986 10.0983V7.09382Z"
                fill="url(#paint0_linear_3119_63409)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3119_63409"
                    x1="3.18028"
                    y1="36.1596"
                    x2="45.4319"
                    y2="4.49656"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#03C3FF" />
                    <stop offset="1" stop-color="#00FFBE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}
